<template>
  <div class="about">
    <el-container style="height: 500px; border: 1px solid #eee">
      <el-header style="text-align: right; font-size: 12px">
        <tou></tou>
      </el-header>
      <el-container>
        <el-aside width="180px" style="background-color: rgb(238, 241, 246)">
          <leftside></leftside>
        </el-aside>
        <el-main>
          <el-date-picker
            v-model="start"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="end"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 100px"
          >
          </el-date-picker>
          <label for="" style="margin-left: 20px">帐号：</label>
          <el-input
            placeholder="请输入内容"
            v-model="input"
            style="width: 150px"
            clearable
          >
          </el-input>
          <label for="" style="margin-left: 20px">姓名：</label>
          <el-input
            placeholder="请输入内容"
            v-model="nameinput"
            style="width: 150px"
            clearable
          >
          </el-input>
          <el-button type="warning" @click="cha" style="margin-left: 10px"
            >查看</el-button
          >
          <el-button type="warning" @click="handleExcel" style="margin-left: 10px"
            >导出</el-button
          >
          <br /><br />
          <!-- 内容区域 -->
          <Breadcrumb></Breadcrumb>
          <h5>总条数：{{ total }}条</h5>
          <!-- 表格 -->
          <div class="table">
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column fixed prop="user_id" label="ID"> </el-table-column>
              <el-table-column prop="nickname" label="用户名"> </el-table-column
              >level_name
              <el-table-column prop="level_name" label="等级"> </el-table-column>
              <el-table-column prop="account" label="电话"> </el-table-column>
              <!-- <el-table-column prop="card_num" label="卡数"> </el-table-column> -->
              <el-table-column prop="reg_time" label="办卡时间"> </el-table-column>
              <el-table-column property="face" label="图片">
                <template slot-scope="scope">
                  <img :src="scope.row.face" @error="defImg()" width="40" height="40" />
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.row)" type="text" size="medium"
                    >选择等级
                  </el-button>
                  <el-button @click="handleClickt(scope.row)" type="text" size="medium"
                    >设置推荐人
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- /End 表格 -->
          <el-dialog title=" " :visible.sync="visible">
            <div style="margin-top: 20px; text-align: left">
              级别:
              <el-select v-model="level" placeholder="级别" @change="ka">
                <el-option
                  v-for="item in option"
                  :key="item.id"
                  :name="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <br />
              <el-button type="success" @click="ok" style="margin: 5%">确定</el-button>
              <el-button :plain="true" @click="no" style="margin: 5%">取消</el-button>
            </div>
          </el-dialog>
          <div>
            <el-popover
              placement="left"
              width="780px"
              trigger="manual"
              :offset="300"
              v-model="visiblet"
              ><br /><br />
              <div class="spbiao">
                推荐人id：
                <el-input
                  v-model="tuijian"
                  placeholder="请输入内容"
                  style="width: 300px"
                  show-password
                ></el-input
                ><br /><br />
                <el-button type="success" @click="oks">确定</el-button>
                <el-button :plain="true" @click="no">取消</el-button>
              </div>
            </el-popover>
          </div>
          <!-- <Pagination></Pagination> -->

          <!-- 分页 -->
          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[20, 40, 60]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- /End 分页 -->
          <!-- /End 内容区域 -->
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import axios from "axios";
import leftside from "../components/leftside.vue";
import tou from "../components/tou.vue";
export default {
  name: "orderinfo",
  components: {
    leftside,
    tou,
  },
  data() {
    return {
      defaultImg: require("../../static/1.png"),
      start: "",
      end: "",
      input: "",
      cardinput: "",
      nameinput: "",
      gridData: [],
      myInstance: null, // axios实例
      tableData: [], // 表格数据
      visible: false,
      visiblet: false,
      currentPage: 1, // 当前页码
      total: 0, // 总条目数
      query: "", // 查询参数
      pagenum: 1, // 当前页码
      pagesize: 20, // 每页显示条数
      user_id: "",
      level: "",
      level_id: "",
      option: [],
      tuijian: "",
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    handleExcel() {
      let fields = {
        // title0: "序号",
        title0: "ID",
        title1: "帐号",
        title2: "姓名",
        title3: "级别",
        title4: "注册时间",
      };
      let arry = [];
      // let i = 0;
      let params = {};
      params.page = 1;
      params.pageSize = 999; //这里也可以直接通过接口返回的total，拿值
      this.$axios
        .post(this.$api.daouser, {
          // cardnum: this.cardinput, // 查询参数
          account: this.input,
          nickname: this.nameinput,
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          console.log(res);
          res.data.forEach((ele) => {
            // i++;
            arry.push({
              // title0: i,
              title0: ele.user_id,
              title1: ele.account,
              title2: ele.nickname,
              title3: ele.level_name,
              title4: ele.reg_time,
            });
          });
          console.log(arry);
          console.log(fields);
          this.$xlsx.xlsx(arry, fields, "excel统计列表");
        });
    },
    defImg() {
      let img = event.srcElement;
      img.src = this.defaultImg;
      img.onerror = null; //防止闪图
    },
    cha() {
      this.pagenum = 1;
      this.getUser();
    },
    getUser() {
      this.$axios
        .post(this.$api.shopinfo, {
          // cardnum: this.cardinput, // 查询参数
          account: this.input,
          nickname: this.nameinput,
          page: this.pagenum, // 当前页码
          page_size: this.pagesize, // 每页显示条数
          start_time: this.start,
          end_time: this.end,
        })
        .then((res) => {
          this.tableData = res.data.list; // 表格数据
          this.total = res.data.count; // 总条目数
        });
    },

    handleClick(row) {
      console.log(row);
      this.user_id = row.user_id;
      this.$axios
        .post(this.$api.level, {
          // id: row.user_id,
        })
        .then((res) => {
          this.visible = true;
          this.option = res.data; // 表格数据
        });
    },
    handleClickt(row) {
      console.log(row);
      this.visiblet = true;
      this.user_id = row.user_id;
    },
    ka(data) {
      console.log(data);
      this.level_id = data;
    },
    oks() {
      console.log(this.user_id);
      console.log(this.level_id);
      //确定添加银行卡
      this.$confirm("请您确定设置, 是否继续?", "提示", {
        //弹框确定继续操作
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.tuijian, {
              user_id: this.user_id,
              recommender_id: this.tuijian,
            })
            .then((res) => {
              if (res.code == 1) {
                //判断是否结算成功
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.visiblet = false;
                this.getUser();
                this.level = "";
                this.level_id = "";
                this.tuijian = "";
                // location.reload() //刷新页面
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.visiblet = false;
        });
    },

    no() {
      //取消添加
      this.$message({
        message: "已取消",
        type: "warning",
      });
      this.visible = false;
      this.visiblet = false;
      this.level = "";
      this.tuijian = "";
    },
    handleSizeChange(val) {
      //改变时
      this.pagesize = val;
      this.getUser();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      //条目改变时
      this.pagenum = val;
      this.getUser();
      console.log(`当前页: ${val}`);
    },
  },
  computed: {},
};
</script>
<style scoped>
.spbiao {
  width: 70%;
  left: 12%;
  min-height: 200px;
  position: fixed;
  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  top: 15%;
  padding: 2%;
}

.a {
  text-decoration: none;
}

.el-menu-item {
  font-size: 20px;
}

.el-header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  color: #333;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.active {
  color: #409eff !important;
}
</style>
